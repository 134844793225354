import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Privacy & Cookies</h1>
          </div>
          <p>
            <strong>Nelly Duff</strong> is committed to protecting your privacy.
            We greatly value our customers and aim to create a second to none
            and safe shopping experience when using our website.&nbsp;We are
            also committed to ensuring that your privacy is protected.
          </p>
          <p>
            <strong>Nelly Duff</strong> is a trading name of
            <strong>Nelly Duff Limited</strong>. Reg no:
            <strong>06957909</strong>, registered in England &amp; Wales. All
            the information you provide, including email address, delivery
            address and credit card details are strictly private and
            confidential. Nelly Duff acknowledges that we are bound by the Data
            Protection Act 1998 and that we will not disclose your details to
            any third party without your express written consent. As a matter of
            good practice, we do not share any personal information with anyone
            and certainly will not sell it to others.
          </p>
          <p>
            The following privacy policy sets out how we use and protect any
            information given via www.nellyduff.com. &nbsp;Should we ask you to
            provide certain information by which you can be identified when
            using this website, rest assured that it will only be used in
            accordance with this privacy statement. We may change this policy
            from time to time by updating this page.
          </p>

          <ul>
            <li>
              <a href="#pp_one">Information collected by our website</a>
            </li>
            <li>
              <a href="#pp_two">Controlling your personal information</a>
            </li>
            <li>
              <a href="#pp_three">Website Security</a>
            </li>
            <li>
              <a href="#pp_four">An Introduction to cookies</a>
            </li>
            <li>
              <a href="#pp_five">
                Your consent for cookie deployment by our website.
              </a>
            </li>
            <li>
              <a href="#pp_six">Cookies used by our website</a>
            </li>
            <li>
              <a href="#pp_seven">Actual cookies deployed</a>
            </li>
            <li>
              <a href="#pp_eight">How to disable cookies</a>
            </li>
          </ul>
          <h2>Information collected by our website</h2>
          <ul className="mid-ul-li">
            <li>
              <h3>What we collect</h3>
              <p>
                For the purpose of making an enquiry or placing an order,
                www.nellyduff.com may collect your name, appropriate contact
                information including email address, postal address as well as
                preferences and interests. Other information relevant to
                customer surveys and/or offers may also be requested.
              </p>
            </li>
            <li>
              <h3>What we do with the information collected</h3>
              <p>
                Nelly Duff collects this information in order to best understand
                your needs and provide you with more information on products and
                services as required. Your information may also be stored in a
                CRM system for internal record keeping. We may use the
                information to improve our products and services. We may
                periodically send promotional emails about new products, special
                offers or other information which we think you may find
                interesting using the email address which you have provided. We
                may contact you by email, phone or post. We may use the
                information to customise the website according to your
                interests.
              </p>
            </li>
          </ul>
          <h2>Controlling your personal information</h2>
          <p>
            If you feel at all uncomfortable, you may choose to restrict the
            collection or use of your personal information in the following
            ways:
          </p>
          <ul>
            <li>
              Whenever you are asked to fill in a form on the website, look out
              for a tick box to indicate that you do not want your information
              to be used by anybody for direct marketing purposes.
            </li>
            <li>
              Of course, if you have previously agreed to Nelly Duff using your
              personal information for direct marketing purposes, you may change
              your mind at any time by emailing our East London gallery.
            </li>
            <li>
              In accordance with GDPR, to request the deletion or removal of
              your data from Nelly Duff, please contact users@nellyduff.xyz
            </li>
          </ul>
          <p>
            Nelly Duff will not sell, distribute or lease your personal
            information to third parties unless we have your permission or are
            required by law to do so. We may use your personal information to
            send you promotional information about third parties which we think
            you may find interesting, but only if given express permission. You
            may request details of personal information which we hold about you
            under the Data Protection Act 1998, for which a small fee may be
            payable. If you would like a copy of the information held, please
            contact Nelly Duff.
          </p>
          <p>
            If you believe that information we are holding is incorrect or
            incomplete, please contact Nelly Duff as soon as possible. Nelly
            Duff we then correct any incorrect information.
          </p>
          <h2>Security</h2>
          <ul className="mid-ul-li">
            <li>
              <h3>Website Security</h3>
              <p>
                Nelly Duff is committed to ensuring that your information is
                secure. In order to prevent unauthorised access or disclosure,
                we have put in place suitable physical, electronic and
                managerial procedures to safeguard and secure the information we
                collect online.
              </p>
            </li>
            <li>
              <h3>Payment Security</h3>
              <p>
                Nelly Duff will take all reasonable technical and organisational
                precautions to prevent the loss, misuse or alteration of your
                personal information. We will never store your credit/debit card
                information on our website. All electronic transactions you make
                to or receive from us will be encrypted using SSL technology via
                Cloudflare Inc and will be transferred and processed by WordPay
                or PayPal. Ofcourse, data transmission over the internet is
                inherently insecure, and we cannot guarantee the security of
                data sent over the internet. You are responsible for keeping
                your password and user details confidential. We will not ask you
                for your password.
              </p>
            </li>
            <li>
              <h3>External Links</h3>
              <p>
                Our website may contain links to other websites of interest.
                However once you have used these links to leave our site, Nelly
                Duff has no control over the externally linked site. Therefore,
                we cannot be responsible for the protection and privacy of any
                information which you provide whilst visiting such sites. In
                addition, such sites are not governed by this privacy statement.
                Nelly Duff always recommends that you exercise caution and read
                the privacy statement applicable to the website in question.
              </p>
            </li>
          </ul>
          <h2>An introduction to cookies</h2>
          <p>
            A cookie is a small data file that is automatically placed onto your
            computer’s hard drive. Cookie files are created by our website and
            placed onto your device in order to analyse and influence your
            preferences or behavior. Analytical cookies are designed to track
            your journey and experience on our website – so that we may tailor
            your experience and gather data. Overall, cookies help us provide
            you with a better website, by enabling us to monitor which pages you
            find useful and which you do not. A cookie in no way gives us access
            to your computer or any information about you, other than the data
            you choose to share with us.
          </p>
          <p>
            The majority of modern web browsers will automatically allow cookies
            to be placed onto your device. However you can usually modify your
            browser’s settings in order to prevent any cookies from being placed
            onto your device. Setting your browser to block cookies may prevent
            you from taking full advantage of our website.
          </p>
          <h3>Cookies deployed by our website:</h3>
          <ul className="mid1-ul-li">
            <li>ensure that functionality operates as intended</li>
            <li>
              remember your choices and preferences during and between visits
            </li>
            <li>
              allow you to share pages via popular social media networks
              including Facebook and Twitter
            </li>
            <li>
              allow you to interact with our website by leaving comments or
              opinions
            </li>
            <li>post enquiries via online forms</li>
            <li>
              track your visit for statistical analysis, allowing us to improve
              the usability, speed and security of our website
            </li>
          </ul>

          <h3>Cookies are not deployed to:</h3>
          <ul className="mid1-ul-li">
            <li>
              collect any personal identifiable information (without your
              express permission)
            </li>
            <li>
              collect any sensitive information (without your express
              permission)
            </li>
            <li>pass data to advertising networks</li>
            <li>pass personally identifiable data to third parties</li>
            <li>pay sales commissions</li>
          </ul>
          <h2>Your consent for cookie deployment by our website</h2>
          <p>
            Upon your first visit to www.nellyduff.com, you may have noticed our
            banner alerting you to the use of cookies by our website. In
            compliance with the 2011 EU Cookie Law, our website operates on an
            ‘Implied Consent’ basis. This means that we have assumed that you
            have ‘opted-in’ for our website to deploy cookies until you choose
            to deactivate them within your browser settings or via the
            instructions below. Below you can learn more about the specific
            cookies deployed by our website, and how they can be disabled. For
            more information on the EU Cookie Law in the UK, we recommend
            visiting the
            <a
              href="http://ico.org.uk/for_organisations/privacy_and_electronic_communications/the_guide/cookies"
              target="_blank"
              rel="noopener"
            >
              Information Commissioner’s Office (ICO)
            </a>
            website where you can find the latest information, guidelines and
            advice on the EU Cookie Law.
          </p>
          <h2>Cookies used by our website</h2>
          <ul className="mid-ul-li">
            <li>
              <h3>Social Network Sharing</h3>
              <p>
                Nelly Duff encourage users to share our content and/or like our
                social media posts across Facebook, Twitter, Instagram etc. In
                order to make ‘Social Sharing’ accessible, our website utilises
                widgets either provided directly from the Social Networks and/or
                via amalgamative widgets from third parties such as AddThis.
                Cookies and privacy implications from the social networks vary
                and will be dependent on your nominated privacy settings with
                each Social Network. Social Sharing buttons will only deploy
                cookies if you are signed to that respective Social Network at
                the time of being on our website.
              </p>
            </li>
            <li>
              <h3>Analytical Tracking</h3>
              <p>
                The Nelly Duff website has Google Analytics installed which
                allows us to track and compile anonymous visitor statistics. The
                information collected ranges from simple traffic volume to the
                type of browser you are viewing our website with. This
                information is valuable to us not just for marketing analysis
                and quantification, but to improve the usability, security and
                load speed of our the gallery website. Google Analytics is a
                popular, secure, flagship webmaster product from Google. The
                privacy and security of Google Analytics data is a high priority
                at Google that you can read more about on Google’s Analytics
                Data Safeguarding page. Google also offer a Google Analytics
                Opt-out Browser Add-on that will allow you to automatically
                Opt-out of all websites that track your activity via Google
                Analytics. Nelly Duff assures you that no personal information
                is collected by Google Analytics.
              </p>
            </li>
            <li>
              <h3>Cookie Consent Check</h3>
              <p>
                Upon your first visit to the Nelly Duff website, you will notice
                a subtle banner on the page alerting you to the fact that
                cookies are being placed onto your computer by the site. In
                order for our website to remember your choice of opting-in, we
                store a cookie which expires after the set amount of days below
                (see table).
              </p>
            </li>
            <li>
              <h3>WordPress</h3>
              <p>
                Our website, either in full or in part, is built on the popular
                open-source CMS framework – WordPress. WordPress utilises
                cookies to allow visitors to register, login and comment on our
                website’s content. If you do not wish to participate in
                commenting on our website, WordPress will not deploy any cookies
                onto your device. Cookies will only be created by WordPress if
                you actively register or comment via the clear forms on our
                website.
              </p>
            </li>
            <li>
              <h3>WooCommerce</h3>
              <p>
                WooCommerce is a popular eCommerce extension for WordPress,
                which we use for our website. WooCommerce uses three cookies in
                order to save your basket contents and session details. No
                personal information is stored in any of the three WooCommerce
                cookies. Cookies deployed by WooCommerce are essential for the
                process of using the cart and checkout functionality.
              </p>
            </li>
            <li>
              <h3>Enquiry Forms</h3>
              <p>
                Visitors can choose to contact us via form(s) on the Nelly Duff
                site. In order to prevent spam enquiries, we protect our forms
                with anti-spam challenges which ensure the visitor is an actual
                living person, rather than a nasty computer bot or spider.
              </p>
            </li>
            <li>
              <h3>Hotjar</h3>
              <p>
                We use Hotjar in order to better understand our users’ needs and
                to optimise this service and experience. Hotjar is a technology
                service that helps us better understand our users experience
                (e.g. how much time they spend on which pages, which links they
                choose to click, what users do and don’t like, etc.) and this
                enables us to build and maintain our service with user feedback.
                Hotjar uses cookies (listed later in this policy) and other
                technologies to collect data on our users’ behavior and their
                devices (in particular device’s IP address (captured and stored
                only in anonymized form), device screen size, device type
                (unique device identifiers), browser information, geographic
                location (country only), preferred language used to display our
                website).
              </p>
              <p>
                Hotjar stores this information in a pseudonymized user profile.
                Neither Hotjar nor we will ever use this information to identify
                individual users or to match it with further data on an
                individual user. For further details, please see Hotjar’s
                privacy policy.
              </p>
              <p>
                You can opt-out to the creation of a user profile, Hotjar’s
                storing of data about your usage of our site and Hotjar’s use of
                tracking cookies on other websites by following this opt-out
                link.
              </p>
            </li>
            <li>
              <h3>Zendesk</h3>
              <p>
                We use Zendesk as our cloud-based help desk management solution
                offering customizable tools to build customer service portal,
                knowledge base and online communities. It can be used as our
                help centre, to raise tickets or Live chat to provide help to
                our customers.
              </p>
              <p>
                You can find more information on cookies stored by Zendesk by
                following this Zendesk’s privacy policy.
              </p>
            </li>
          </ul>
          <h2>Actual cookies deployed</h2>
          <p>
            Below is a table of information which lists all cookies deployed and
            used on our website.
          </p>
          <table id="cookies-table">
            <thead>
              <tr>
                <th className="privact-th">Cookie Names</th>
                <th className="privact-th">Cookie Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="privact-td">complianceCookie</td>
                <td className="privact-td">
                  This cookie is set by our website once you have seen and
                  acknowledged our Cookie banner. This cookie will expire and
                  automatically delete itself after<strong> 14 </strong>days.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_ga</td>
                <td className="privact-td">
                  The single default cookie for Google Universal Analytics. This
                  sole cookie used by Goole Analytics stores a unique client
                  identifier (Client ID) which is set randomly. This cookie is
                  set to expire after 24 months (2 years) and is refreshed each
                  time you visit our website.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_utma</td>
                <td className="privact-td">
                  A Google Analytics cookie, which keeps track of the number of
                  times, a visitor has been to the site, when their first visit
                  was, and when their last visit occurred.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_utmb</td>
                <td className="privact-td">
                  A Google Analytics cookie, which creates a timestamp of the
                  exact moment when a visitor enters a site.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_utmc</td>
                <td className="privact-td">
                  A Google Analytics cookie, which creates a timestamp of the
                  exact moment when a visitor leaves the site.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_utmv</td>
                <td className="privact-td">
                  Used for reporting in Google Analytics classifying the
                  visitor.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_utmz</td>
                <td className="privact-td">
                  A Google Analytics cookie which tracks where the visitor came
                  from, what search engine was used, what link was clicked on,
                  what keywords used, and where in the world the site was
                  accessed from.
                </td>
              </tr>
              <tr>
                <td className="privact-td">
                  loc, uid/uit, psc, di/dt, atuvc/bt/ssc/ssh/sshs/xtc
                </td>
                <td className="privact-td">
                  Allows users to share content via Social Networking websites
                  and email
                </td>
              </tr>
              <tr>
                <td className="privact-td">wp-settings-time-[UID]</td>
                <td className="privact-td">
                  Serveral WP Settings cookies may be set if you log into the
                  website. The number on the end is your individual user ID from
                  the users database table. This is used to customise your view
                  of admin interface, and possibly also the main site interface.
                </td>
              </tr>
              <tr>
                <td className="privact-td">
                  wordpress_test_cookiewordpress_
                  <br /> wordpress_logged_in
                </td>
                <td className="privact-td">
                  These WordPress session cookies are created if and when you
                  log into WordPress either as an administrator or contributor
                  to the website.
                </td>
              </tr>
              <tr>
                <td className="privact-td">
                  comment_authorcomment_author_email
                  <br /> comment_author_url
                </td>
                <td className="privact-td">
                  If you choose to submit a discussion/comment to our website,
                  you will be asked to provide certain information about
                  yourself including your name, email and website address.
                </td>
              </tr>
              <tr>
                <td className="privact-td">devicePixelRatio</td>
                <td className="privact-td">
                  This cookie records your device’s pixel ratio. If your screen
                  resolution is a retina or Hi DPI screen, then the website may
                  choose to serve you higher resolution graphics.
                </td>
              </tr>
              <tr>
                <td className="privact-td">woocommerce_cart_hash</td>
                <td rowspan="2" className="privact-td">
                  These two cookies store information about the cart/basaket as
                  a whole and help track data changes in WooCommerce. No
                  personal information is stored within this cookie.
                </td>
              </tr>
              <tr>
                <td className="privact-td">woocommerce_items_in_cart</td>
              </tr>
              <tr>
                <td className="privact-td">wp_woocommerce_session_</td>
                <td className="privact-td">
                  This unique user session cookie pairs the user with their
                  cart/basket contents stored on the database. No personal
                  information is stored within this cookie.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjClosedSurveyInvites</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set once a visitor interacts
                  with a Survey invitation modal popup. It is used to ensure
                  that the same invite does not reappear if it has already been
                  shown.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjDonePolls</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set once a visitor completes a
                  poll using the Feedback Poll widget. It is used to ensure that
                  the same poll does not reappear if it has already been filled
                  in.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjMinimizedPolls</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set once a visitor minimizes a
                  Feedback Poll widget. It is used to ensure that the widget
                  stays minimizes when the visitor navigates through your site.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjDoneTestersWidgets</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set once a visitor submits their
                  information in the Recruit User Testers widget. It is used to
                  ensure that the same form does not re-appear if it has already
                  been filled in.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjMinimizedTestersWidgets</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set once a visitor minimizes a
                  Recruit User Testers widget. It is used to ensure that the
                  widget stays minimizes when the visitor navigates through your
                  site.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjIncludedInSample</td>
                <td className="privact-td">
                  Hotjar cookie. This session cookie is set to let Hotjar know
                  whether that visitor is included in the sample which is used
                  to generate funnels.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjShownFeedbackMessage</td>
                <td className="privact-td">
                  This cookie is set when a visitor minimizes or completes
                  Incoming Feedback. This is done so that the Incoming Feedback
                  will load as minimized immediately if they navigate to another
                  page where it is set to show.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_hjid</td>
                <td className="privact-td">
                  Hotjar cookie. This cookie is set when the customer first
                  lands on a page with the Hotjar script. It is used to persist
                  the random user ID, unique to that site on the browser. This
                  ensures that behavior in subsequent visits to the same site
                  will be attributed to the same user ID.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_zlcid</td>
                <td className="privact-td">
                  This unique user session cookie is used to store the Zopim
                  Live Chat ID used to identify a device across visits, and also
                  across a single chat session.
                </td>
              </tr>
              <tr>
                <td className="privact-td">_zprivacy</td>
                <td className="privact-td">
                  This unique user session cookie is used to store the user’s
                  preference to disable Live Chat, and cease using cookies. The
                  __zprivacy cookie is stored across visits.
                </td>
              </tr>
            </tbody>
          </table>
          <h2>How to disable cookies</h2>
          <p>
            Most modern browsers allow you to control your cookie settings for
            all websites that you browse. You can disable cookie deployment
            completely by editing your browser settings, however in doing this
            you may be limiting the functionality that is displayed on our
            website. To learn how to disable cookies on your preferred browser
            we recommend reading the following&nbsp;
            <a
              href="http://support.google.com/accounts/bin/answer.py?hl=en&amp;answer=61416"
              target="_blank"
              rel="noopener"
            >
              advice posted by Google
            </a>
            .
          </p>
          <p>
            If you are concerned about cookies tracking your movements on the
            Internet then you may be wish to know about Spyware. Spyware is the
            name given to a particular band of cookies that track personal
            information about you. There are many antispyware programs that you
            can use to prevent this from happening. Learn more about antispyware
            software –{" "}
            <a
              title="Spyware"
              href="http://en.wikipedia.org/wiki/Spyware"
              target="_blank"
              rel="noopener"
            >
              http://en.wikipedia.org/wiki/Spyware.{" "}
            </a>
            You call also disable Google Analytics on all websites by
            downloading the{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener"
            >
              Google Analytics Opt-out Browser Add-on
            </a>
            .
          </p>
          <h2>Right of access</h2>
          <p>
            We are happy to provide unfettered access to the information that we
            hold about you. If you would like to request a copy, please send a
            request to&nbsp;
            <a href="mailto:users@nellyduff.xyz">users@nellyduff.xyz</a>
            &nbsp; or using our handy web form
            <a href="https://www.nellyduff.com/contact">here</a>. Alternatively,
            you can write to us at:
            <br /> Nelly Duff
            <br /> 156 Columbia Road
            <br /> London
            <br /> E2 7RG
          </p>
          <h2>Contact information</h2>
          <p>
            Our postal address is:
            <br /> Nelly Duff
            <br /> 156 Columbia Road
            <br /> London
            <br /> E2 7RG
          </p>
          <p>Telephone: 020 7033 9683</p>
          <p>
            Email for privacy and data requests:
            <br /> <a href="mailto:users@nellyduff.xyz">users@nellyduff.xyz</a>
            <br /> …or using our web form{" "}
            <a href="https://www.nellyduff.com/contact">here</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
