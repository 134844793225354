import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Terms</h1>
          </div>
          <p>
            This page states the terms and conditions under which you use the{" "}
            <a href="https://www.nellyduff.com">www.nellyduff.com</a> website,
            at home or in store. Use of the Nelly Duff website constitutes full
            agreement with these Terms &amp; Conditions are stated below.
          </p>
          <h2>1. Payment for Goods &amp; Delivery Charges</h2>
          <p>
            Nelly Duff can only supply goods that are ordered online via the
            website, email, telephone or in person at our East London gallery.
            All payments, including shipping costs, must be paid by credit,
            debit card, paypal or cash if ordered in person. Nelly Duff will not
            accept any other form of payment. All transactions are completed on
            a secure server and Nelly Duff will process all card transactions in
            store.
          </p>
          <p>
            Nelly Duff is not responsible for parcels damaged during shipping.
          </p>
          <p>
            Nelly Duff is not responsible for international duties or taxes. All
            international duties and taxes in relationship to your order are the
            responsibility of the purchaser.
          </p>
          <h2>2. Delivery Times</h2>
          <p>
            Orders are collated at 9am Monday to Thursday. All orders received
            after 9am will not be dispatched until the next shipping day. All UK
            parcels are shipped via the 1st Class Recorded ‘Signed For’ service.
          </p>
          <h2>3. Prices</h2>
          <p>
            Prices are as stated on the website and are subject to change at any
            time without notice. Any prices quoted for framing are valid for a
            period of four weeks after which the price may need to be re-quoted
            and is liable to change.
          </p>
          <p>
            In the event that our website experiences a technical error and
            fails to charge for a frame as part of your order, Nelly Duff will
            reach out to request the correct payment manually, or refund your
            order in full.
          </p>
          <h2>4. Nelly Duff warranties</h2>
          <p>
            Nelly Duff is unable to provide any warranties in relation to the
            goods provided over and above its statutory obligation to provide
            goods of satisfactory quality in accordance with the Sale of Goods
            Act 1979 as amended by the Sale and Supply of Goods Act 1994.
          </p>
          <h2>5. Limited Liability</h2>
          <p>
            Except in relation to liability for death or personal injury Nelly
            Duff’s liability for negligence shall be limited to the total value
            of the transaction. Nelly Duff shall not be liable for any indirect,
            consequential, or special losses even if Nelly Duff has previously
            been advised of the likelihood of such losses.
          </p>
          <h2>6. Availability of the Website &amp; Goods</h2>
          <p>
            Nelly Duff will use all reasonable endeavours to ensure that the
            website does not have any problems and that the goods displayed are
            available. However we cannot guarantee the availability of the
            website or that any goods on the site are in stock or available. In
            the case of an ordered item becoming out of stock, items will be
            allocated on a time-ordered basis and a full refund offered to all
            excess orders.
          </p>
          <h2>7. Typographical Errors</h2>
          <p>
            In the event that a Nelly Duff item is mistakenly listed at an
            incorrect price, Nelly Duff reserves the right to refuse or cancel
            any orders placed for product listed at the incorrect price. Nelly
            Duff reserves the right to refuse or cancel any such orders whether
            or not the order has been confirmed and your credit card charged. If
            your credit card has already been charged for the purchase and your
            order is cancelled, Nelly Duff shall issue a refund in the amount of
            the incorrect price.
          </p>
          <h2>8. Intellectual Property Rights</h2>
          <p>
            The rights in the designs and content of this website are owned by
            Nelly Duff. They are protected by copyright and other intellectual
            property rights. You may not copy, reproduce, modify, distribute,
            display, post or transmit any part of this site without permission.
            All trademarks, product names and company names or logos featured on
            this website are the property of their owners.
          </p>
          <h2>9. Returns &amp; Exchange</h2>
          <p>
            All products sold at www.n<a href="/">ellyduff.com</a>&nbsp; and at
            our East London gallery are verified by us as the genuine article.
          </p>
          <p>
            Nelly Duff is confident you will be satisfied with your purchase
            however if not, returns are accepted within 14 days of receipt or
            one month of purchase, whichever comes first. As long as the piece
            is returned mint within their original packaging alongside original
            proof of purchase Nelly Duff is happy to exchange items purchased
            in-store for store credit, refunds are available at the gallery’s
            discretion.
          </p>
          <p>
            Shipped artworks can be returned to us via post and refunded minus
            the shipping and packaging cost.&nbsp;Nelly Duff cannot accept
            responsibility for loss or damage to returned goods whilst in
            transit. If you do have to return any item, kindly use a trackable
            system such as a parcel delivery or courier service.
          </p>
          <p>
            However, once it has left the UK, Nelly Duff cannot be held
            accountable for damages or imperfections in a framed artwork, and
            our guarantee becomes immediately void.
          </p>
          <p>
            All orders found to be faulty or not as described will be refunded
            in full after we have received the item back at the gallery.
            &nbsp;All orders found to be faulty or not as described must be
            reported to users@nellyduff.xyz, with photographs within 14 days of
            receipt otherwise all guarantees and insurance are expired.
            &nbsp;Nelly Duff will endeavour to replace items after this, however
            this is not guaranteed.
          </p>
          <p>
            Please note that we cannot offer a returns policy for our framing
            service. Frames are bespoke made according to our customers’
            requirements and therefore cannot be returned or exchanged.
          </p>
          <p>
            Once a frame has been collected and left the gallery, the customer
            takes full responsibility for the welfare and condition of the frame
            in transportation and hanging. If it does ascertain any superficial
            damage once it has left the gallery, the gallery is no longer
            responsible for said damage.
          </p>
          <p>
            All refunds will be processed in GBP. We cannot be held accountable
            to changes in exchange rates.
          </p>
          <p>
            If you have any problems with your order or wish to cancel/exchange
            an artworks please&nbsp;
            <a href="/contact">contact us</a>&nbsp;to arrange a refund,
            replacement or exchange. These terms and conditions do not affect
            your statutory rights as a consumer.
          </p>
          <h2>10. Privacy &amp; Cookies Policy</h2>
          <p>
            Nelly Duff acknowledges that it is bound by the Data Protection Act
            1998 and that it will not disclose your details to any third party
            without your express written consent. As a matter of good practice,
            we do not share any personal information with anyone and certainly
            will not sell it to others. For more information about our Privacy
            and Cookies Policies, please see&nbsp;
            <a href="/privacy">Privacy &amp; Cookies</a>.
          </p>
          <h2>11. Right to alter Terms &amp; Conditions</h2>
          <p>
            The Nelly Duff website is constantly changing and these terms and
            conditions may be updated from time to time. Nelly Duff reserves the
            right to change the terms and conditions without notice.
          </p>
          <h2>12. Third Party Links</h2>
          <p>
            In an attempt to provide increased value to our visitors, Nelly Duff
            may link to sites operated by third parties. However, even if the
            third party is affiliated with Nelly Duff, Nelly Duff has no control
            over these linked sites, all of which have separate privacy and data
            collection practices, independent of Nelly Duff. These linked sites
            are only for your convenience and therefore you access them at your
            own risk.
          </p>
          <h2>13. Shipping Offer Exemptions</h2>
          <p>
            Artworks that are not safe to be rolled into our Nelly Duff shipping
            tubes and require special flat-packing are not eligible in our free
            shipping offers. These pieces require additional shipping crate and
            courier costs as indicated. Please contact the gallery directly to
            gain a quote.
          </p>
          <h2>14. Gift Vouchers</h2>
          <p>
            Gift Vouchers may be redeemed&nbsp;within 12 months of purchase,
            against all items on sale.
          </p>
          <h2>15.&nbsp;Variations in Artworks</h2>
          <p>
            All artwork descriptions and&nbsp;dimensions listed on the website
            are intended to be as accurate as possible. However, sizes can vary
            from print to print. Please do not purchase pre-made frames in
            advance of receiving and thoroughly checking an artwork. &nbsp;We
            cannot be held accountable for any framing costs incurred in any
            circumstance.
          </p>
          <h2>16.&nbsp;Uncollected Artworks</h2>
          <p class="p1">
            <span class="s1">
              Nelly Duff will happily hold your unframed and framed artworks for
              up to 3 weeks. Please collect your artwork within this time
              period. If you do not collect your product within this time
              period, then the artwork may be put back in stock,&nbsp;in which
              case you will be refunded for your artwork. If you have
              ordered&nbsp;a bespoke frame, refunds can be&nbsp;offered
              according to the bespoke nature of the order and at the
              discretion&nbsp;of the seller.&nbsp;
            </span>
          </p>
          <p class="p1">
            <span class="s1">
              If&nbsp;you would like us to hold your item for longer you must
              advise us, quoting your order number. The additional length of
              time for which we&nbsp;will hold your goods is solely at our
              discretion. &nbsp;Though we take as much care as possible with
              handling and storing the artworks and frames, due to restricted
              storage space and a high volume of orders coming and going,
              &nbsp;Nelly Duff can not take responsibility for damage that comes
              to the artwork or frame beyond our standard storage period of 3
              weeks, 1 week for oversized pieces. Once the standard or
              pre-arranged longer storage period has elapsed, if an order
              remains uncollected, we may refund the original cost of your order
              and return the goods to stock for re-sale at the gallery’s
              discretion.
            </span>
          </p>
          <p>
            Please note that Nelly Duff does offer same or future day, time-slot
            allocated delivery within most of London for artworks and frames if
            you are unable to collect. This generally ranges in price from £15 –
            £30, please contact the gallery for further information.
          </p>
          <h2>17. Reserved&nbsp;Artworks</h2>
          <p class="p1">
            <span class="s1">
              A deposit payment will reserve an artwork for you for 28 days. You
              will only be able to&nbsp;collect your artwork if you have paid in
              full. If you do not pay the remaining balance and collect your
              product within this time period, then the artwork will be put back
              in stock, and you will be refunded.&nbsp;
            </span>
          </p>
          <p>
            If&nbsp;you would like us to hold them for longer you must advise
            us, quoting your order number. The additional length of time for
            which we&nbsp;will hold your goods is solely at our
            discretion.&nbsp;If you would like us to hold your larger items for
            longer than 5 business days after&nbsp;we have advised you that they
            are available for collection, we may do so upon payment of a
            non-refundable storage fee. This storage fee is&nbsp;usually £15 per
            item per week. You must advise us as soon as we notify you that your
            goods are available if you want to arrange a longer
            storage&nbsp;period. Once the standard, or pre-arranged longer
            storage period has elapsed we will refund the original cost of your
            order, excluding any&nbsp;longer term storage payment, and return
            the goods to stock for re-sale.
          </p>
          <h2>
            <strong>18. Framing Guarantee</strong>
          </h2>
          <p>
            Nelly Duff offers a 12 month guarantee on all frames sold from the
            gallery. This guarantee covers the quality and structural integrity
            of the frame and not any physical damage to the frame that should
            occur once the frame has left the gallery or passed the agreed safe
            storage period of three weeks for standard frames, one week for
            oversized frames. Under this guarantee Nelly Duff agrees to correct
            any issues associated with the integrity and quality of the framing
            that should arise within 12 months of the customer’s order, any
            repairs or corrections beyond this time would incur a cost which
            will be advised by the gallery.
          </p>
          <p>
            The return of frames to the gallery for corrections and frame refits
            during and beyond the period of the guarantee is solely the
            customer’s responsibility and Nelly Duff will not cover any costs
            for frame transportation or offer compensation for travel time
            incurred.
          </p>
          <p>&nbsp;</p>
          <h2>19. Edition numbers</h2>
          <p>
            Upon the completion of your order you are automatically assigned an
            edition number that will be listed in your confirmation email on the
            purchase of a limited edition print. However, we cannot guarantee
            that this will be the edition number you will receive. We will
            always endeavour to send you your artwork in absolutely mint
            condition and within the delivery time you select on checkout, to
            ensure we achieve this, it can mean that we will assign you a new
            edition number if the one selected was not in mint condition, or off
            site. If you would like to request a specific edition number, or
            would like to request the one you are assigned in your confirmation
            email, please get in touch as soon as you have placed your order to
            allow time for your preferred edition number to be confirmed. We do
            our best to make sure you receive the edition number you are
            originally assigned, but we can never fully guarantee this will be
            the case. If you would like to confirm your exact edition number
            prior to dispatch, give us a call or drop us an email to do this.
          </p>
          <p>
            Edition numbers cannot be changed after your order has been
            dispatched, and if you would like to change edition number once your
            order has arrived with you, the customer is responsible for all
            costs incurred. If you would like to request a specific number,
            please contact the gallery as soon as your order is made.
          </p>
          <h2>20. Own Art</h2>
          <p>
            Apply for a 0% APR loan between £100 and £2500 when purchasing
            eligible art.
          </p>
          <p>
            Finance is provided by Novuna Personal Finance, a division of Novuna
            Capital (UK) PLC, authorised and regulated by the Financial Conduct
            Authority. Loans are 0% APR Representative.
          </p>
          <p>
            All Own Art purchases must be delivered to the address registered to
            your application, or collected in store with ID and proof of address
            matching the Own Art registered address.
          </p>
          <p>
            Our standard returns policy does not apply to Own Art purchases.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p class="p1">Many thanks for reading,</p>
          <p>best</p>
          <p>Nelly and the team.</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Terms;
